<!-- eslint-disable vue/camelcase -->
<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="EXAM_DETAILS"
      tooltip-title="EXAM_DETAILS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div v-if="isLoading">
      <Loader :content="true" />
    </div>
    <div v-else class="w-full mt-5">
      <DetailCard :details="cardDetials" custom-classes="flex-col" />
      <div>
        <Heading heading="Subjects" heading-size="pb-0 text-xl" />
        <div v-if="isEmpty(subjectsExamList)">
          <NoRecordFound />
        </div>
        <TableWrapper v-else>
          <THead>
            <TRHead>
              <TH v-for="(head, index) in tableHeaders" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(exam, index) in subjectsExamList" :key="index">
              <TD>
                {{ exam.subject.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                {{ $filters.formattedDate(exam.planned_at, DATE_TIME_CONSTANT.DATE) }}
              </TD>
              <TD>
                {{ $filters.formattedDate(exam.planned_at, DATE_TIME_CONSTANT.time12H) }}
              </TD>
              <TD>
                {{ exam.exam_duration || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                {{ GENERAL_CONSTANTS.MINUTES }}
              </TD>
              <TD>
                {{ exam.total_marks || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                {{ (exam.room && exam.room.title) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                {{
                  (exam.invigilator &&
                    `${exam.invigilator.first_name} ${exam.invigilator.last_name} `) ||
                  GENERAL_CONSTANTS.NOT_APPLICABLE
                }}
              </TD>
              <TD>
                {{ exam.status || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                <TableAction
                  :action-list="exam.actionsList"
                  :idx="index"
                  :current-user="exam"
                  @action="typeAction"
                />
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
      </div>
    </div>
    <Pagination
      v-if="subjectsCount > GENERAL_CONSTANTS.RECORD_LIMIT"
      v-show="!isLoading"
      :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
      :max-range="paginationCounts(subjectsCount, GENERAL_CONSTANTS.RECORD_LIMIT)"
      @filterRecord="filterRecords"
    />
    <ExamSubjectModal
      v-if="isModalView"
      :modal="isModalView"
      :section-id="section_id"
      :exam-schedule-id="currentExamId"
      :edit="currentSelectedSubject"
      @toggle="toggleSubjectModal"
    />
    <MarkResultModal
      v-if="isResultModalView"
      :modal="isResultModalView"
      :subject-details="{ exam_schedule_id: currentExamId, ...currentSelectedSubject }"
      @toggle="toggleResultModal"
    />

    <UIConfirmationModal
      v-if="deleteModelShow"
      :modal="deleteModelShow"
      heading="DELETE_EXAM"
      message="Are you sure you want to delete "
      button-text="Delete"
      :name="currentSelectedSubject.subject.title"
      remaining-message="subject from exam ?"
      @cancel="toogleDeleteModel"
      @confirm="deleteSubjectById"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions } from 'vuex'
import GENERAL_CONSTANTS, { SCOPE_LEVELS } from '@src/constants/general-constants'
import Loader from '@components/BaseComponent/Loader.vue'
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import Heading from '@components/Heading.vue'
import ExamSubjectModal from '@views/admin/modals/AddAndEditExamSubjectModal.vue'
import { objectDeepCopy } from '@utils/generalUtil'
import TableAction from '@components/TableAction.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import isEmpty from 'lodash/isEmpty'
import MarkResultModal from '@views/exam-planner/exam-subject-result/MarkExamSubjectModal.vue'
import {
  paginationRangeHandler,
  paginationCounts,
} from '@src/components/BaseComponent/pagination.util.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { mapState } from 'vuex'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
import DetailCard from '@/src/components/UiElements/DetailCard.vue'
export default {
  components: {
    TitleWrapper,
    Loader,
    Heading,
    ExamSubjectModal,
    TableAction,
    UIConfirmationModal,
    MarkResultModal,
    NoRecordFound,
    Pagination,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
    DetailCard,
  },

  mixins: [scrollMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      DATE_TIME_CONSTANT,
      examDetails: {},
      tableHeaders: [
        'Subject Title',
        'Exam Start Date',
        'Exam Start Time',
        'Exam Duration',
        'Total Marks',
        'Room',
        'Invigilator',
        'Status',
        'Actions',
      ],
      isLoading: false,
      requestLogs: [],
      isModalView: false,
      actionList: [],
      currentSelectedSubject: null,
      subjectsExamList: [],
      deleteModelShow: false,
      isResultModalView: false,
      subjectsCount: 0,
      cardDetials: {},
      section_id: '',
      allowedEditStatuses: ['pending', 'upcoming'],
    }
  },
  page: {
    title: 'Non GPA Grading Structure',
  },
  computed: {
    ...mapState('layout', ['currentClassScope', 'currentSectionScope']),
    currentExamId() {
      return this.$route.params.id
    },
  },
  watch: {
    currentClassScope: {
      handler() {
        this.$router.push({ name: 'exams' })
      },
      deep: true,
    },
    currentSectionScope: {
      handler() {
        this.$router.push({ name: 'exams' })
      },
      deep: true,
    },
  },
  mounted() {
    this.filterRecords()
    this.setRightbarData()
  },
  methods: {
    isEmpty,
    paginationCounts,
    toogleDeleteModel() {
      this.deleteModelShow = !this.deleteModelShow
      if (!this.deleteModelShow) this.currentSelectedSubject = null
    },
    async filterRecords(range) {
      this.isLoading = true
      let id = this.currentExamId

      let filterQueries = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'exam_schedule_id', id),
        },
      }

      let payload = { ...paginationRangeHandler(range), ...filterQueries }

      Promise.all([this.getExamDetails(id), this.getExamsSubjectsList(payload)]).then(
        (response) => {
          let [details] = response[0]
          this.examDetails = details?.data
          this.section_id = this.examDetails.section_id
          this.subjectsExamList = response[1].data.records
          this.getActionsListForExams(this.subjectsExamList)
          this.subjectsCount = response[1].data.meta.total_records
          this.setRightbarData(this.subjectsCount)
          this.cardDetials = {
            title: this.examDetails.title,
            examType: this.examDetails.exam_type && this.examDetails.exam_type.title,
            description: this.examDetails.description,
            section: this.examDetails.section.title,
          }

          this.isLoading = false
        },
      )
    },
    tableAtionListHandler(status) {
      let actionList = [{ name: 'ADD_MARKS' }, { name: 'VIEW_RESULT' }, { name: 'Delete' }]
      if (this.allowedEditStatuses.includes(status)) {
        actionList.unshift({ name: 'Edit' })
      }
      return actionList
    },
    deleteSubjectById() {
      this.deleteSubject(this.currentSelectedSubject.id)
    },
    async deleteSubject(id) {
      let [res, error] = await this.deleteExamSubject(id)
      this.getExamSubejectList()
      if (!error) this.toogleDeleteModel()
    },
    setRightbarData(length = 0) {
      const rightBarContent = {
        header: {
          title: 'EXAM_DETAILS',
          buttons: [
            {
              title: 'ADD_EXAM_SUBJECT',
              classes: ['primary-button-right'],
              action: {
                func: () => {
                  this.toggleSubjectModal()
                },
              },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [{ text: 'Subjects', value: length }],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    toggleSubjectModal(payload) {
      this.isModalView = !this.isModalView
      if (!this.isModalView) this.currentSelectedSubject = null
      if (payload) this.getExamSubejectList()
    },

    typeAction(action, currentExam, idx) {
      this.currentSelectedSubject = objectDeepCopy(currentExam)
      switch (action) {
        case 'Edit':
          this.toggleSubjectModal()
          break
        case 'Delete':
          this.toogleDeleteModel()
          break
        case 'ADD_MARKS':
          this.toggleResultModal()
          break
        case 'VIEW_RESULT':
          this.$router?.push({
            name: 'exam-result-view',
            params: {
              id: this.currentExamId,
              subjectId: this.currentSelectedSubject.id,
            },
          })
          break
      }
    },
    toggleResultModal(payload) {
      this.isResultModalView = !this.isResultModalView
      if (!this.isResultModalView) this.currentSelectedSubject = null
      if (payload) this.getExamSubejectList()
    },
    getExamSubejectList() {
      let id = this.currentExamId
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'exam_schedule_id', id),
        },
      }
      this.getExamsSubjectsList(payload).then((response) => {
        this.subjectsExamList = response.data.records
        this.getActionsListForExams(this.subjectsExamList)
      })
    },
    getActionsListForExams(examsList) {
      examsList.forEach((exam) => (exam.actionsList = this.tableAtionListHandler(exam.status)))
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('exams', ['getExamDetails', 'getExamsSubjectsList', 'deleteExamSubject']),
  },
}
</script>
