<template>
  <ValidationObserver @submit="addExamSubject">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>
          {{ modalHeading }}
        </span>
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="examSubject['subject_id']"
            title="SUBJECT"
            rules="required"
            :label="'title'"
            reduce="id"
            :options="subjectsList"
            :search-through-api="true"
            @search="getsubjectsList"
          />
          <InputBox
            v-model.number="examSubject['exam_duration']"
            type="text"
            name="EXAM_DURATION"
            title="EXAM_DURATION"
            label="EXAM_DURATION"
            placeholder="EXAM_DURATION"
            :rules="`required|numeric|min_value:${EXAM_CONSTANTS.MIN_EXAM_TIME_DURATION}`"
          />
        </InputFieldWrapper>
        <UiDateTimePicker
          v-model="examSubject['planned_at']"
          title="EXAM_START_DATE_TIME"
          rules="required"
        />
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="examSubject['invigilator_id']"
            title="Invigilator"
            label="full_name"
            reduce="id"
            :search-through-api="true"
            :options="invigilatorsList"
            @search="getinvigilatorsList"
          />
          <UiSingleSelect
            v-model="examSubject['room_id']"
            title="ROOM"
            label="title"
            reduce="id"
            :options="roomsList"
            :search-through-api="true"
            @search="getRoomsList"
          />
        </InputFieldWrapper>
        <InputBox
          v-model.number="examSubject['total_marks']"
          type="text"
          name="TOTAL_MARKS"
          title="TOTAL_MARKS"
          label="TOTAL_MARKS"
          placeholder="TOTAL_MARKS"
          rules="required|double"
        />
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>
              {{ buttonText }}
            </span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import { Form as ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiDateTimePicker from '@src/components/UiElements/UIDateTimePicker.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import EXAM_CONSTANTS from '@src/constants/exam-constants.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
export default {
  components: {
    UiModalContainer,
    InputBox,
    ValidationObserver,
    Loader,
    UiSingleSelect,
    UiDateTimePicker,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Object,
      default: () => {},
    },
    sectionId: {
      type: String,
      default: '',
    },
    examScheduleId: {
      type: String,
      default: '',
    },
  },
  emits: ['toggle'],
  data() {
    return {
      EXAM_CONSTANTS,
      roomsList: [],
      examSubject: {
        subject_id: '',
        exam_duration: '',
        planned_at: '',
        invigilator_id: '',
        room_id: '',
        exam_schedule_id: '',
        total_marks: '',
      },
      subjectsList: [],
      isLoading: false,
      invigilatorsList: [],
      FILTER_KEYS,
    }
  },
  computed: {
    buttonText() {
      return this.edit ? 'Update' : 'Save'
    },
    modalHeading() {
      return this.edit ? 'Edit Exam Subject' : 'Add Exam Subject'
    },
  },
  created() {
    if (this.edit) this.setFormData()
    this.init()
    this.examSubject.exam_schedule_id = this.examScheduleId
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    getinvigilatorsList(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      this.getTeacherWithFilters(payload).then((response) => {
        this.invigilatorsList = response?.data?.records || []
      })
    },
    getsubjectsList(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.sectionId),
        },
      }
      this.classSubjects(payload).then((response) => {
        this.subjectsList = response?.data?.records || []
      })
    },
    getRoomsList(query = '') {
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getRooms(payload).then((response) => {
        this.roomsList = response?.records || ''
      })
    },
    init() {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', this.sectionId),
        },
      }
      Promise.all([
        this.getTeacherWithFilters(),
        this.classSubjects(payload),
        this.getRooms(),
      ]).then((response) => {
        this.invigilatorsList = response[0]?.data?.records || []
        this.subjectsList = response[1]?.data?.records || []
        this.roomsList = response[2]?.records || []
      })
    },
    addExamSubject() {
      this.isLoading = true
      let payload = { subject: this.examSubject }
      if (this.edit) payload.id = this.edit?.id
      this.createExamSubject(payload)
        .then(() => {
          this.$emit('toggle', true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setFormData() {
      this.examSubject.subject_id = this.edit?.subject_id || ''
      this.examSubject.exam_duration = this.edit?.exam_duration || ''
      this.examSubject.planned_at = this.edit?.planned_at || ''
      this.examSubject.invigilator_id = this.edit?.invigilator_id || ''
      this.examSubject.exam_schedule_id = this.edit?.exam_schedule_id || ''
      this.examSubject.room_id = this.edit?.room?.id || ''
      this.examSubject.total_marks = this.edit?.total_marks || ''
    },
    ...mapActions('subjects', ['classSubjects']),
    ...mapActions('exams', ['createExamSubject']),
    ...mapActions('room', ['getRooms']),
    ...mapActions('teacher', ['getTeacherStats', 'getTeacherWithFilters']),
  },
}
</script>
