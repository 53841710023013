<template>
  <ValidationObserver @submit="submitResult">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>Add Subject Marks</span>
      </template>
      <template v-slot>
        <div :class="$style.minHieght60" class="main-content">
          <DetailCard :details="examDetails" custom-classes="flex-col" class="mt-5" />
          <div v-if="isLoading">
            <div class="mt-28">
              <Loader :content="true" />
            </div>
          </div>
          <div v-else-if="!isLoading && isEmpty(currentStudentList)">
            <NoRecordFound class="my-28" />
          </div>
          <TableWrapper v-else id="markExamSubjectModal">
            <THead>
              <TRHead>
                <TH v-for="head in tableHeaders" :key="head">
                  {{ head }}
                </TH>
              </TRHead>
            </THead>
            <TBody>
              <TRBody v-for="(student, index) in currentStudentList" :key="index">
                <TD>
                  <singleUserDisplay
                    :user="student"
                    :is-object-concated="false"
                    label="full_name"
                    :image="true"
                  />
                </TD>
                <TD>
                  <UiInput
                    v-model="student['obtained_marks']"
                    :hide-title="true"
                    type="text"
                    :name="`MARKS ${index}`"
                    title="MARKS"
                    label="MARKS"
                    placeholder="MARKS"
                    :rules="`max_value:${examDetails.totalMarks}|double`"
                    class="bottom"
                    error-custom-classes="absolute -bottom-3.5"
                  />
                </TD>
                <TD>
                  <UiInput
                    v-model="student['private_comment']"
                    :hide-title="true"
                    :no-error="true"
                    type="text"
                    :name="`Comments ${index}`"
                    title="Comment"
                    label="Comment"
                    placeholder="Comment"
                  />
                </TD>
              </TRBody>
            </TBody>
          </TableWrapper>
        </div>
        <Pagination
          v-if="studentCounts > RECORD_LIMIT"
          v-show="!isLoading"
          :record-limit="RECORD_LIMIT"
          class="mb-5 mx-5"
          :max-range="paginationCounts(studentCounts, RECORD_LIMIT)"
          @filterRecord="getStudentList"
        />
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isDataSubmitting || isEmpty(currentStudentList)">
            <span v-if="isDataSubmitting">
              <Loader />
            </span>
            <span v-else>Submit</span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { mapActions, mapState } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import singleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import isEmpty from 'lodash/isEmpty'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import {
  paginationRangeHandler,
  paginationCounts,
  getPageNumberWithRange,
} from '@src/components/BaseComponent/pagination.util.js'
import { Form as ValidationObserver } from 'vee-validate'
import { fullName } from '@/src/utils/settings/tenant-user.util'
import DetailCard from '@src/components/UiElements/DetailCard.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    UiModalContainer,
    ValidationObserver,
    Loader,
    singleUserDisplay,
    Pagination,
    UiInput,
    NoRecordFound,
    DetailCard,
    UIButton,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    subjectDetails: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['toggle'],
  data() {
    return {
      GENERAL_CONSTANTS,
      isEmpty,
      isLoading: false,
      RECORD_LIMIT: GENERAL_CONSTANTS.RECORD_LIMIT,
      tableHeaders: ['Student Name', 'Marks', 'Comment'],
      studentList: [],
      currentStudentList: [],
      studentCounts: [],
      isDataSubmitting: false,
      examDetails: {},
    }
  },
  created() {
    this.examDetails = this.getDetails()
  },
  mounted() {
    this.getStudentList()
  },
  methods: {
    paginationCounts,
    getDetails() {
      return {
        title: this.subjectDetails.title,
        subject: this.subjectDetails.subject.title,
        description: this.subjectDetails.description,
        totalMarks: this.subjectDetails.total_marks,
        examDuration: this.subjectDetails.exam_duration,
        status: this.subjectDetails.status,
      }
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    async submitResult() {
      this.isDataSubmitting = true
      let payload = { subject_exam_schedule_marks: this.getPayload() }
      const [res, err] = await this.submitBulkResultOfSubjectExam({
        payload,
        id: this.subjectDetails.id,
      })
      if (err) this.isDataSubmitting = false
      else this.$emit('toggle', true)
    },
    getPayload() {
      let resultList = []
      let studentList = Object.values(this.studentList).flat()
      studentList.forEach((student) => {
        if (student.obtained_marks || student.obtained_marks === 0)
          resultList.push({
            obtained_marks: student.obtained_marks,
            private_comment: student.private_comment || '',
            subject_exam_schedule_id: this.subjectDetails.id,
            student_id: student.id,
          })
      })
      return resultList
    },
    async getStudentList(range) {
      this.isLoading = true
      let payload = {
        ...paginationRangeHandler(range),
        ...{ subject_exam_schedule_id: this.subjectDetails.id },
      }

      let pageNo = getPageNumberWithRange(payload)
      let isStudentListAlreadyExisted = this.studentList.hasOwnProperty(`${pageNo}`)

      let [response, error] = await this.getPendingStudentList(payload)

      if (isStudentListAlreadyExisted) this.currentStudentList = this.studentList[`${pageNo}`]
      else {
        this.currentStudentList = response.data.records
        this.studentList = {
          ...this.studentList,
          ...{ [pageNo]: response.data.records },
        }
      }
      this.currentStudentList.forEach((student) => fullName(student))
      this.studentCounts = response.data.meta.total_records
      this.isLoading = false
    },
    ...mapActions('exams', ['submitBulkResultOfSubjectExam', 'getPendingStudentList']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght60 {
  min-height: 60vh;
}
</style>
